import JSEncrypt from "jsencrypt/bin/jsencrypt.min";

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDWWVkz8ey+HA8RlUTZTOvasgRB\n" +
  "ipaZGZiLV8VY9vvT+z7A0FgtFKu3ogJSlgv/bIKlMkuLDzuLSfz97GWxpl+6Bnaz\n" +
  "IPD7XA9sBxsXLzg/MGGBOgIK7HlGipDe1XIqKn0zhyxBw3bHL+bQTI0jQGJe62ps\n" +
  "8O/1afl/GmlBbuk+MQIDAQAB";

const privateKey =
  "MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBANZZWTPx7L4cDxGV\n" +
  "RNlM69qyBEGKlpkZmItXxVj2+9P7PsDQWC0Uq7eiAlKWC/9sgqUyS4sPO4tJ/P3s\n" +
  "ZbGmX7oGdrMg8PtcD2wHGxcvOD8wYYE6AgrseUaKkN7VcioqfTOHLEHDdscv5tBM\n" +
  "jSNAYl7ramzw7/Vp+X8aaUFu6T4xAgMBAAECgYEA0w1csLHnafTUvXpdNRzxKf+Y\n" +
  "AzTDPfIe6iy86vEdWkcLLey2tYSh44jIgqqtG42rQZ85FK4l8esjCJ6ILxhjLUkY\n" +
  "2/UjcUAAMHebKhXi37noyXoJdQRzPB3L8vqOp9ZGonxVVYZC7oEFUOiwK/euSMMi\n" +
  "OVKA8Tm2XdRB8aUG/bUCQQDwFSjjadPk/igTyFSKlBpDKUCzyPGR/ViDo+awdya6\n" +
  "sS1rg0j0dOhuM7N8q3lYsG9LHkytdCzNzVpFxrElQ3AbAkEA5I9rqT53JhE0PbvU\n" +
  "P9izC3i8zD2V8q5b083Pzs5nkb8YfgIDM0nhP4xUUz5Oq/e9xMKRtwcFlmDCQUEN\n" +
  "0npnowJBAM41PnNl2EwdSIsFTCfi4VPCQQKqm2KLOdWHl4MGNEuyFpL7plPhfin5\n" +
  "WjbAiW91y4G3uqBY4oq50MmRyWF6NPUCQDQULe7padLFtsaeRtWpddqWT0amfcVH\n" +
  "T2sqiUBRLa47AcSshrgoDGgjfI9fMiPjFl2ADB3NPsuuDnU58oFUHMsCQAKS4vQI\n" +
  "ZKLTXaqJTMMI+XA/8bQ5Yr+gZ0ICqbWyA3uY/DU6bVxl3LMHxZtd7/dC0itOUFfR\n" +
  "53ljBAjkVABbmrk=";

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey); // 设置公钥
  return encryptor.encrypt(txt); // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPrivateKey(privateKey); // 设置私钥
  return encryptor.decrypt(txt); // 对数据进行解密
}
